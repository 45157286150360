<template>
    <div class="managments-company-admin">
        <div class="managments-company-admin-title">
            <h2>ניהול מערכת נהלים</h2>
        </div>
        <div class="managments-company-admin-wrapper">
            <div class="managments-company-admin-wrapper-options">
                <el-button @click="state='Departments'" :type="state=='Departments'?'success':'primary'">ניהול מחלקות</el-button>
            </div>
            <div class="managments-company-admin-wrapper-content">
                <component :is="state" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref,defineAsyncComponent } from "vue";

export default {
    components:{
        Departments:defineAsyncComponent(() => import('../admin/components/Departments.vue'))
    },
    setup () {
        
        const state = ref('Departments')

        return {
            state
        }
    }
}
</script>

<style scoped>
    .managments-company-admin{
        width: 100%;
        height: 100%;
    }
    .managments-company-admin-title{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        background: var(--secondary);
    }
    .managments-company-admin-wrapper{
        width: 100%;
        max-width: 1200px;
        height: calc(100% - 50px);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }
   
    .managments-company-admin-wrapper-options{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .managments-company-admin-wrapper-content{
        width: 100%;
        flex: 1;
    }
</style>